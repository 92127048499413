.intro {
    padding: 42px 44px;
    width: 1021px;
    right: 122px;
    top: 218px;
    
  }
  .basic-details {
    text-transform: uppercase;
  }

  .header{
    background: antiquewhite;
    text-align: -webkit-center;
  }

  #container2 {
    width: 201px; 
    position: relative;
    margin:0 auto;
    line-height: 1.4em;
    right: 157px
    }
    
    @media only screen and (max-width: 479px){
        #container2 { width: 61%; right: 10%; }
        
    }
    @media only screen and (max-width: 800px){
      #container2 { right: 50px; width: 61%; }
      
  }
  @media only screen and (min-width: 1020px){
    #container2 { right: 100px; width: 50%; }
    
}
@media only screen and (min-width: 1080px){
  #container2 { right: 155px; width: 40%; }
  
}